import { Component, Input } from '@angular/core';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';

@Component({
  selector: 'of-can-execute-order',
  standalone: true,
  imports: [],
  template: `
    @if (canExecute) {
      <ng-content></ng-content>
    }
  `
})
export class CanExecuteOrderComponent {
  public canExecute = false;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  ngOnInit(): void {
    this.canExecute = canExecuteOrder(this.orderStatus);
  }
}

export function canExecuteOrder(orderStatus: CodeNameItem | null): boolean {
  return (
    orderStatus?.code === ORDER_STATUS_VALUES['acknowledged'].code ||
    orderStatus?.code === ORDER_STATUS_VALUES['partiallyExecuted'].code
  );
}
