import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { Security } from './security.model';
import { showErrorDialog } from '../shared/utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-localstorage';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SecuritiesService {
  private apiEndpoint = `${environment.apiEndpoint}/api/securities`;
  public securitiesSet: string | null | undefined = null;
  public securitiesSet$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private dbService: NgxIndexedDBService,
    private storage: LocalStorageService
  ) {}

  public listSecurities$(): Observable<Security[]> {
    return this.dbService.getAll<Security>('securities').pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getLastClose$(faSecurityCode: string): Observable<number> {
    return this.http
      .get(`${this.apiEndpoint}/last-close/${faSecurityCode}`, {
        responseType: 'text'
      })
      .pipe(
        map((res) => Number.parseFloat(res)),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public refreshSecuritiesCache(): void {
    this.loadSecuritiesIntoCache();
  }

  public checkAndLoadSecuritiesIntoCache(): void {
    const securitiesSet = this.storage.get<string>('securities_set');

    if (!securitiesSet || moment().diff(moment(securitiesSet), 'days') > 1) {
      this.loadSecuritiesIntoCache();
    } else {
      this.securitiesSet = securitiesSet;
      this.logger.log('Successfully loaded securities from cache');
      this.securitiesSet$.next(true);
    }
  }

  private loadSecuritiesIntoCache(): void {
    this.http
      .get<Security[]>(this.apiEndpoint)
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      )
      .subscribe((securities) => {
        this.dbService.clear('securities').subscribe(() => {
          this.dbService.bulkAdd('securities', securities).subscribe(() => {
            this.logger.log('Successfully loaded securities to cache');
            const securitiesSet = moment().toISOString();
            this.storage.set('securities_set', securitiesSet);
            this.securitiesSet = securitiesSet;
            this.securitiesSet$.next(true);
          });
        });
      });
  }
}
