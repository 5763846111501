import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { Observable } from 'rxjs';
import { PartialExecutionViewItem } from './partial-execution-view-item.model';
import { PartialExecutionsService } from './partial-executions.service';
import { canViewOrders } from '../orders/can-view-orders.component';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';

@Component({
  selector: 'of-partial-executions',
  standalone: true,
  imports: [AsyncPipe, DatePipe, DecimalPipe, GridModule, RouterModule],
  templateUrl: './partial-executions.component.html',
  styles: ``
})
export class PartialExecutionsComponent implements OnInit {
  public partialExecutions$: Observable<PartialExecutionViewItem[]> | null = null;
  public canViewOrderDetails: boolean;

  constructor(
    private partialExecutionsService: PartialExecutionsService,
    authService: OrderFlowAuthService
  ) {
    this.canViewOrderDetails = canViewOrders(authService);
  }

  public ngOnInit(): void {
    this.partialExecutions$ = this.partialExecutionsService.listOrdersWithPartialExecutions$();
  }
}
