import { Component, Input } from '@angular/core';
import { PartialExecution } from './partial-execution.model';
import { DatePipe, DecimalPipe, LowerCasePipe } from '@angular/common';
import { AvatarCardComponent } from '../shared/avatar-card.component';

@Component({
  selector: 'of-partial-executions-card',
  standalone: true,
  imports: [AvatarCardComponent, DatePipe, DecimalPipe, LowerCasePipe],
  template: `
    @if (partialExecutions) {
      <div class="card mb-3">
        <div class="card-header d-flex justify-content-between px-2">
          <h5 class="card-title m-0">EXECUTIONS</h5>
        </div>
        <div class="card-body p-0">
          <table class="table m-0">
            <thead>
              <tr>
                <th>Recorded</th>
                <th>Transaction Ref.</th>
                <th class="text-end">Quantity / Nominal</th>
                <th class="text-center">Recorded by</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              @for (partialExecution of partialExecutions; track $index) {
                <tr>
                  <td class="align-middle">
                    {{ partialExecution.created | date: 'dd/MM/yyyy HH:mm' }}
                    @if (partialExecution.isFinalExecution) {
                      <span class="badge text-success-emphasis bg-success-subtle ms-2">Final</span>
                    }
                  </td>
                  <td class="align-middle">{{ partialExecution.transactionRefNumber }}</td>
                  <td class="align-middle text-end">
                    {{ partialExecution.quantity | number: '1.0-10' }}
                    @if (partialExecution.quantityType) {
                      <small [title]="partialExecution.quantityType.name"
                        >({{ partialExecution.quantityType.name[0] }})</small
                      >
                    }
                  </td>
                  <td class="d-flex justify-content-center">
                    <of-avatar-card [user]="partialExecution.createdBy"></of-avatar-card>
                  </td>
                  <td class="align-middle">
                    @if (partialExecution.comments) {
                      <div [innerHTML]="partialExecution.comments"></div>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }
  `
})
export class PartialExecutionsCardComponent {
  @Input()
  public partialExecutions: PartialExecution[] | null = null;
}
