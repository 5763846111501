import { Component, Input } from '@angular/core';

@Component({
  selector: 'of-ack-status-badge',
  standalone: true,
  imports: [],
  template: `
    @if (acknowledged) {
      <span class="e-badge e-badge-success" title="Acknowledged">ACKNOWLEDGED</span>
    } @else {
      <span class="e-badge e-badge-secondary" title="N/A">PENDING</span>
    }
  `
})
export class AckStatusBadgeComponent {
  @Input()
  public acknowledged: Date | null = null;
}
