import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../orders/order.model';
import { UpdateMultipleOrdersStatusRequestFormConfig } from '../shared/comments.model';
import { ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { RouterModule } from '@angular/router';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

@Component({
  selector: 'of-execute-multiple-orders',
  standalone: true,
  imports: [DropDownListModule, ReactiveFormsModule, RouterModule, TextBoxModule],
  template: `
    @if (data) {
      <form [formGroup]="form">
        <h5 mat-dialog-title class="p-3">EXECUTE ORDERS</h5>
        <div mat-dialog-content class="px-3 pb-3 d-flex flex-column gap-2">
          @for (order of data; track order) {
            <div class="d-flex justify-content-between">
              <div class="d-flex gap-3">
                <strong> {{ order.id }}</strong>
                <div>
                  {{ order.transactionType }} {{ order.quantity }} {{ order.security.name }} (account:
                  {{ order.portfolio.shortName }})
                </div>
              </div>
              <a [routerLink]="['/orders', order.id]" target="_blank">Order Details</a>
            </div>
          }
          <div>
            <ejs-dropdownlist
              placeholder="Execution status"
              [dataSource]="statusOptions"
              [fields]="{ text: 'name', value: 'code' }"
              floatLabelType="Auto"
              formControlName="newStatus"
            ></ejs-dropdownlist>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-danger" [disabled]="!form.valid" (click)="onCancelClick()" cdkFocusInitial>
                EXECUTE ORDERS
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class ExecuteMultipleOrdersComponent {
  public form: FormGroup = new FormGroup({});
  public comments: string | null = null;
  public statusOptions = [ORDER_STATUS_VALUES['executed'], ORDER_STATUS_VALUES['partiallyExecuted']];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Order[],
    public dialogRef: MatDialogRef<ExecuteMultipleOrdersComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<UpdateMultipleOrdersStatusRequestFormConfig>({
      comments: this.fb.control<string | null>(null),
      orderIds: this.fb.control<number[] | null>(this.data.map((order) => order.id)),
      newStatus: this.fb.control<string | null>(null)
    });
  }

  public onCancelClick(): void {
    this.dialogRef.close({
      ...this.form.value,
      newStatus: Object.values(ORDER_STATUS_VALUES).find((s) => s.code === this.form.value.newStatus)
    });
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
