import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../orders/order.model';
import { OrdersService } from '../orders/orders.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UpdateOrderStatusRequestFormConfig } from '../shared/comments.model';
import { CodeNameShortItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { showSuccessSnackbar } from '../shared/utils';
import { RouterModule } from '@angular/router';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

export interface StatusOption extends CodeNameShortItem {
  disabled: boolean;
}

@Component({
  selector: 'of-execute-order-dialog',
  standalone: true,
  imports: [DropDownListModule, ReactiveFormsModule, RouterModule, TextBoxModule],
  template: `
    @if (data) {
      <form [formGroup]="form">
        <h5 mat-dialog-title class="p-3">EXECUTE ORDER {{ data.id }}</h5>
        <div mat-dialog-content class="px-3 pb-3 d-flex flex-column gap-2">
          <div>
            {{ data.transactionType }} {{ data.quantity }} {{ data.security.name }} (account:
            {{ data.portfolio.shortName }})
          </div>
          <a [routerLink]="['/orders', data.id]" target="_blank">Order Details</a>
          <div>
            <ejs-dropdownlist
              placeholder="Execution status"
              [dataSource]="statusOptions"
              [fields]="{ text: 'name', value: 'code', disabled: 'disabled' }"
              floatLabelType="Auto"
              formControlName="newStatus"
            ></ejs-dropdownlist>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-danger" [disabled]="!form.valid" (click)="onExecuteClick()" cdkFocusInitial>
                EXECUTE ORDER
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class ExecuteOrderDialogComponent {
  public form: FormGroup = new FormGroup({});
  public comments: string | null = null;
  public statusOptions: StatusOption[] | null = null;

  constructor(
    public dialogRef: MatDialogRef<ExecuteOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private ordersService: OrdersService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.statusOptions = [ORDER_STATUS_VALUES['executed'], ORDER_STATUS_VALUES['partiallyExecuted']].map((status) => ({
      ...status,
      disabled: status.code === ORDER_STATUS_VALUES['partiallyExecuted'].code && status.code === data.status.code
    }));
  }

  ngOnInit(): void {
    this.form = this.fb.group<UpdateOrderStatusRequestFormConfig>({
      comments: this.fb.control<string | null>(null),
      newStatus: this.fb.control<string | null>(null)
    });
  }

  public onExecuteClick(): void {
    if (this.form) {
      this.form.disable();
      this.ordersService
        .updateOrderStatus$(this.data.id, {
          ...this.form.value,
          orderId: this.data.id,
          newStatus: Object.values(ORDER_STATUS_VALUES).find((s) => s.code === this.form.value.newStatus)
        })
        .subscribe(() => showSuccessSnackbar(this.snackBar, `Order ${this.data.id} sucessfully updated.`));
    }

    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
