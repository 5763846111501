import { Component, Input } from '@angular/core';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';

@Component({
  selector: 'of-can-record-partial-execution',
  standalone: true,
  imports: [],
  template: `
    @if (canRecordPartialExecution) {
      <ng-content></ng-content>
    }
  `
})
export class CanRecordPartialExecutionComponent {
  public canRecordPartialExecution = false;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  @Input()
  public remainingQuantity: number | null = null;

  ngOnInit(): void {
    this.canRecordPartialExecution = canRecordPartialExecution(this.orderStatus, this.remainingQuantity);
  }
}

export function canRecordPartialExecution(orderStatus: CodeNameItem | null, remainingQuantity: number | null): boolean {
  return (
    (orderStatus?.code === ORDER_STATUS_VALUES['partiallyExecuted'].code &&
      remainingQuantity &&
      remainingQuantity > 0) ||
    false
  );
}
