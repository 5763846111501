@if (order) {
  <div class="card">
    <div class="card-header d-flex justify-content-between px-2">
      <div class="d-flex justify-content-between align-items-center gap-1">
        <a [routerLink]="['/orders']" class="btn btn-outline-primary border-0"><i class="bi bi-arrow-left"></i></a>
        <h4 class="card-title m-0">ORDER {{ order.id }}</h4>
        <div class="btn-group btn-group-sm">
          <of-can-preview-order [orderStatus]="order.status">
            <button class="btn btn-outline-secondary border-0" (click)="viewPdf(order.id)">
              <i class="bi bi-eye" title="Preview order"></i>
            </button>
          </of-can-preview-order>
          <of-can-record-partial-execution [orderStatus]="order.status" [remainingQuantity]="order.remainingQuantity">
            <button class="btn btn-outline-secondary border-0" (click)="recordPartialExecution()">
              <i class="bi bi-currency-dollar" title="Record partial execution"></i></button
          ></of-can-record-partial-execution>
        </div>
      </div>
      <div class="d-flex flex-column gap-2 align-items-end">
        <of-order-status-badge [status]="order.status"></of-order-status-badge>
        @if (order.sentToBroker) {
          <small>Sent to broker on {{ order.sentToBroker | date: 'dd/MM/yyyy HH:mm' }}</small>
        }
      </div>
    </div>
    <div class="card-body p-0">
      <table class="table table-condensed table-borderless">
        <tbody>
          <tr>
            <th>Prepared</th>
            <td>
              <span [title]="formatDate(order.created, 'dd/MM/yyyy HH:mm', 'en-uk')">{{
                order.created | timeago
              }}</span>
              by <span [title]="order.createdBy.name"> {{ order.createdBy.initials }}</span>
            </td>
          </tr>
          <tr>
            <th>FA Ext ID</th>
            <td>{{ order.faExternalId }}</td>
          </tr>
          <tr>
            <th>Broker</th>
            <td>{{ order.broker.name }}</td>
          </tr>
          <tr>
            <th>Custodian</th>
            <td>{{ order.custodian.name }}</td>
          </tr>
          <tr>
            <th>Portfolio</th>
            <td>{{ order.portfolio.internalName }}</td>
          </tr>
          @if (order.isEamAccount && order.portfolio.externalName) {
            <tr>
              <th>EAM Portfolio Ext ID</th>
              <td>
                {{ order.portfolio.externalName }}
              </td>
            </tr>
          }
          <tr>
            <th>Account</th>
            <td>{{ order.account.name }} ({{ order.account.currency }})</td>
          </tr>
          <tr>
            <th>Instruction Date</th>
            <td>{{ order.instructionDate | date }}</td>
          </tr>
          <tr>
            <th>Transaction Date</th>
            <td>{{ order.transactionDate | date }}</td>
          </tr>
          <tr>
            <th>Purchase or Sell</th>
            <td>{{ order.transactionType }}</td>
          </tr>
          <tr>
            <th>Type of Security</th>
            <td>{{ order.security.type }}</td>
          </tr>
          <tr>
            <th>Class 3 of Security</th>
            <td>{{ order.security.class3 }}</td>
          </tr>
          <tr>
            <th>Name of Security</th>
            <td>{{ order.security.name }}</td>
          </tr>
          <tr>
            <th>ISIN of Security</th>
            <td>{{ order.security.isin }}</td>
          </tr>
          <tr>
            <th>Trade Code of Security</th>
            <td>{{ order.security.tradeCode }}</td>
          </tr>
          <tr>
            <th>Security Currency</th>
            <td>{{ order.security.currency }}</td>
          </tr>
          @if (order.tdMaturityDate) {
            <tr>
              <th>Time Deposit Maturity Date</th>
              <td>{{ order.tdMaturityDate | date }}</td>
            </tr>
          }
          @if (order.tdTenor) {
            <tr>
              <th>Time Deposit Tenor</th>
              <td>{{ order.tdTenor }}</td>
            </tr>
          }
          @if (order.tdRate || order.tdBestRate) {
            <tr>
              <th>Time Deposit Rate</th>
              <td>
                @if (order.tdBestRate) {
                  Best rate
                } @else {
                  {{ order.tdRate | percent: '1.2-4' }}
                }
              </td>
            </tr>
          }
          @if (order.fxRate) {
            <tr>
              <th>{{ order.account.currency }}/{{ order.security.currency }} FX Rate</th>
              <td>{{ order.fxRate | number: '1.0-10' }}</td>
            </tr>
          }
          <tr>
            <th>{{ order.quantityType.name }}</th>
            <td>{{ order.quantity | number: '1.0-10' }}</td>
          </tr>
          <tr>
            <th>Execution Instruction</th>
            <td>{{ order.executionInstruction.name }}</td>
          </tr>
          <tr>
            <th>Execution Method</th>
            <td>{{ order.executionMethod.name }}</td>
          </tr>
          <tr>
            <th>Order Type</th>
            <td>{{ order.orderType }}</td>
          </tr>
          @if (order.limitPrice) {
            <tr>
              <th>Limit Price</th>
              <td>
                @if (order.security.multiplier === 100) {
                  {{ order.limitPrice | percent: '1.2-4' }}
                } @else {
                  {{ order.limitPrice | number: '1.0-10' }}
                }
              </td>
            </tr>
          }
          <tr>
            <th>TIF</th>
            <td>{{ order.tif }}</td>
          </tr>
          <tr>
            <th>Product knowledge</th>
            <td>{{ order.passedProductKnowledgeCheck ? 'Checked' : 'Not checked' }}</td>
          </tr>

          @if (order.attachment) {
            <tr>
              <th>Attachment</th>
              <td>
                <button class="btn btn-sm btn-outline-secondary" (click)="viewAttachment(order.attachment)">
                  VIEW ATTACHMENT
                </button>
              </td>
            </tr>
          }
          @if (order.isDeleted) {
            <tr>
              <th>Deleted</th>
              <td>{{ order.deleted | date: 'dd/MM/yyyy HH:mm' }} by {{ order.deletedBy }}</td>
            </tr>
            <tr>
              <th>Delete comments</th>
              <td>{{ order.deletedComments }}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
}
