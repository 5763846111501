import { Component } from '@angular/core';
import { CanWriteOrdersComponent } from '../orders/can-write-orders.component';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { SecuritiesService } from '../securities/securities.service';

@Component({
  selector: 'of-settings-nav-menu',
  standalone: true,
  imports: [CanWriteOrdersComponent],
  template: `
    <of-can-write-orders>
      <li class="nav-item dropdown">
        <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-gear"></i>
        </a>
        <ul class="dropdown-menu">
          <li>
            <button class="dropdown-item" (click)="clearPortfoliosCache()">REFRESH PORTFOLIOS</button>
          </li>
          <li>
            <button class="dropdown-item" (click)="clearSecuritiesCache()">REFRESH SECURITIES</button>
          </li>
        </ul>
      </li>
    </of-can-write-orders>
  `
})
export class SettingsNavMenuComponent {
  constructor(
    private portfoliosService: PortfoliosService,
    private securitiesService: SecuritiesService
  ) {}

  public clearPortfoliosCache(): void {
    this.portfoliosService.refreshPortfoliosCache();
  }

  public clearSecuritiesCache(): void {
    this.securitiesService.refreshSecuritiesCache();
  }
}
