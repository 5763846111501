<div class="card mb-3">
  <div class="card-header d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center gap-3">
      <h5 class="card-title m-0">ORDERS WITH PARTIAL EXECUTIONS</h5>
    </div>
  </div>
  <div class="card-body p-0">
    <ejs-grid
      #grdOrders
      [dataSource]="partialExecutions$ | async"
      [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
      [allowPaging]="true"
      [pageSettings]="{ pageCount: 20 }"
      [allowExcelExport]="true"
      [allowPdfExport]="true"
      [allowTextWrap]="true"
    >
      <e-columns>
        <e-column field="id" headerText="#" textAlign="Right" width="20" [isPrimaryKey]="true">
          <ng-template #template let-data>
            @if (canViewOrderDetails) {
              <a [routerLink]="['/orders', data.id]">{{ data.id }}</a>
            } @else {
              {{ data.id }}
            }
          </ng-template>
        </e-column>
        <e-column field="transactionType" headerText="Type" width="50"></e-column>
        <e-column field="securityCurrency" headerText="Ccy" width="30"></e-column>
        <e-column field="quantity" headerText="Qty / Nominal" width="60" textAlign="Right">
          <ng-template #template let-data>
            {{ data.quantity | number: '1.0-10' }}
            <small [title]="data.quantityType">({{ data.quantityType[0] }})</small>
          </ng-template>
        </e-column>
        <e-column field="securityName" headerText="Security" width="150">
          <ng-template #template let-data>
            {{ data.securityName }} <small>({{ data.securityIsin }})</small>
          </ng-template>
        </e-column>
        <e-column field="broker" headerText="Broker" width="50"></e-column>
        <e-column field="custodian" headerText="Custodian" width="50"></e-column>
        <e-column field="portfolioShortName" headerText="Portfolio" width="80">
          <ng-template #template let-data>
            {{ data.portfolioShortName }}
            @if (data.portfolioExternalName) {
              <small> ({{ data.portfolioExternalName }})</small>
            }
          </ng-template>
        </e-column>
        <e-column field="partialExecutions" headerTextAlign="Center" width="220">
          <ng-template #headerTemplate let-data>
            <table class="table table-sm table-borderless m-0">
              <thead>
                <tr>
                  <th colspan="3">Partial Executions</th>
                </tr>
                <tr>
                  <th class="text-start" style="width: 140px">Timestamp</th>
                  <th>Transaction Ref.</th>
                  <th class="text-end">Qty / Nominal</th>
                </tr>
              </thead>
            </table>
          </ng-template>
          <ng-template #template let-data>
            <table class="table table-sm table-striped m-0 p-0">
              <tbody>
                @for (partialExecution of data.partialExecutions; track $index) {
                  <tr>
                    <td class="align-middle" style="width: 140px">
                      {{ partialExecution.created | date: 'dd/MM/yyyy HH:mm' }}
                    </td>
                    <td class="align-middle">{{ partialExecution.transactionRefNumber }}</td>
                    <td class="align-middle text-end">{{ partialExecution.quantity | number: '1.0-10' }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
