import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { PartialExecutionViewItem } from './partial-execution-view-item.model';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { showErrorDialog } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class PartialExecutionsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/orders/partial-executions`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog
  ) {}

  public listOrdersWithPartialExecutions$(): Observable<PartialExecutionViewItem[]> {
    return this.http.get<PartialExecutionViewItem[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
