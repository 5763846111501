import { Component, Input } from '@angular/core';

@Component({
  selector: 'of-check-status-badge',
  standalone: true,
  imports: [],
  template: `
    @if (checkPassed === 'Passed') {
      <span class="e-badge e-badge-success" title="Passed">CHECKED</span>
    } @else if (checkPassed) {
      <span class="e-badge e-badge-danger" title="Sent back for revision">REVISING</span>
    } @else {
      <span class="e-badge e-badge-secondary" title="N/A">PENDING</span>
    }
  `
})
export class CheckStatusBadgeComponent {
  @Input()
  public checkPassed: string | null = null;
}
