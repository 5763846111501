import { Routes } from '@angular/router';
import { ApproveOrderComponent } from './approve/approve-order.component';
import { CannotApproveOrderComponent } from './approve/cannot-approve-order.component';
import { MsalGuard } from '@azure/msal-angular';
import { canApproveOrderGuard } from './approve/can-approve-order.guard';
import { LandingComponent } from './home/landing.component';
import { AppUnavailableComponent } from './core/app-unavailable.component';
import { LoginComponent } from './auth/login.component';
import { LoginFailedComponent } from './auth/login-failed.component';
import { LogoutComponent } from './auth/logout.component';
import { CheckOrderComponent } from './check/check-order.component';
import { canCheckOrderGuard } from './check/can-check-order.guard';
import { CannotCheckOrderComponent } from './check/cannot-check-order.component';
import { OrdersComponent } from './orders/orders.component';
import { DeleteOrderComponent } from './orders/delete-order/delete-order.component';
import { canDeleteOrderGuard } from './orders/delete-order/can-delete-order.guard';
import { OrderDetailsComponent } from './orders/order-details/order-details.component';
import { CreateOrderComponent } from './orders/create-order/create-order.component';
import { canCreateOrderGuard } from './orders/create-order/can-create-order.guard';
import { canViewOrdersGuard } from './orders/can-view-orders.guard';
import { canWriteBrokerGuard } from './brokers/create-broker/can-write-broker.guard';
import { CreateBrokerComponent } from './brokers/create-broker/create-broker.component';
import { BrokersComponent } from './brokers/brokers.component';
import { EditBrokerComponent } from './brokers/create-broker/edit-broker.component';
import { CreateCustodianComponent } from './custodians/custodian-form/create-custodian.component';
import { EditCustodianComponent } from './custodians/custodian-form/edit-custodian.component';
import { CustodiansComponent } from './custodians/custodians.component';
import { canWriteCustodianGuard } from './custodians/custodian-form/can-write-custodian.guard';
import { systemReadyGuard } from './shared/system-ready.guard';
import { EditOrderComponent } from './orders/edit-order/edit-order.component';
import { canEditOrderGuard } from './orders/edit-order/can-edit-order.guard';
import { BulkUploadOrdersComponent } from './orders/bulk-upload-orders/bulk-upload-orders.component';
import { PartialExecutionsComponent } from './partial-executions/partial-executions.component';
import { canViewPartialExecutionsGuard } from './partial-executions/can-view-partial-executions.guard';

export const routes: Routes = [
  {
    path: 'approve',
    children: [
      { path: 'forbidden', component: CannotApproveOrderComponent, canActivate: [MsalGuard] },
      {
        path: ':id',
        component: ApproveOrderComponent,
        canActivate: [MsalGuard, canApproveOrderGuard, systemReadyGuard]
      }
    ]
  },
  {
    path: 'brokers',
    children: [
      { path: 'create', component: CreateBrokerComponent, canActivate: [MsalGuard, canWriteBrokerGuard] },
      { path: ':id/edit', component: EditBrokerComponent, canActivate: [MsalGuard, canWriteBrokerGuard] },
      { path: '', pathMatch: 'full', component: BrokersComponent, canActivate: [MsalGuard, canWriteBrokerGuard] }
    ]
  },
  {
    path: 'check',
    children: [
      { path: 'forbidden', component: CannotCheckOrderComponent, canActivate: [MsalGuard] },
      { path: ':id', component: CheckOrderComponent, canActivate: [MsalGuard, canCheckOrderGuard, systemReadyGuard] }
    ]
  },
  {
    path: 'custodians',
    children: [
      { path: 'create', component: CreateCustodianComponent, canActivate: [MsalGuard, canWriteCustodianGuard] },
      { path: ':id/edit', component: EditCustodianComponent, canActivate: [MsalGuard, canWriteCustodianGuard] },
      { path: '', pathMatch: 'full', component: CustodiansComponent, canActivate: [MsalGuard, canWriteCustodianGuard] }
    ]
  },
  { path: 'home', redirectTo: '/orders' },
  { path: 'login', component: LoginComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'orders',
    children: [
      {
        path: 'bulk-upload',
        component: BulkUploadOrdersComponent,
        canActivate: [MsalGuard, canCreateOrderGuard, systemReadyGuard]
      },
      {
        path: 'create',
        component: CreateOrderComponent,
        canActivate: [MsalGuard, canCreateOrderGuard, systemReadyGuard]
      },
      { path: ':id', component: OrderDetailsComponent, canActivate: [MsalGuard, canViewOrdersGuard, systemReadyGuard] },
      {
        path: ':id/edit',
        component: EditOrderComponent,
        canActivate: [MsalGuard, canEditOrderGuard, systemReadyGuard]
      },
      {
        path: ':id/delete',
        component: DeleteOrderComponent,
        canActivate: [MsalGuard, canDeleteOrderGuard, systemReadyGuard]
      },
      {
        path: '',
        pathMatch: 'full',
        component: OrdersComponent,
        canActivate: [MsalGuard, canViewOrdersGuard, systemReadyGuard]
      }
    ]
  },
  {
    path: 'partial-executions',
    component: PartialExecutionsComponent,
    canActivate: [MsalGuard, canViewPartialExecutionsGuard]
  },
  { path: 'unavailable', component: AppUnavailableComponent },
  { path: '', pathMatch: 'full', component: LandingComponent }
];
