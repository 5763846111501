import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { combineLatest, filter, map, tap } from 'rxjs';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { SecuritiesService } from '../securities/securities.service';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'of-landing',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: `
    @if (!authService.loggedIn) {
      <div class="d-flex justify-content-center align-items-center gap-4">
        <button class="btn btn-outline-primary" (click)="login()">PLEASE LOG IN</button>
      </div>
    } @else {
      <div class="d-flex flex-column gap-4">
        <div class="d-flex justify-content-center align-items-center gap-3">
          <p class="m-0"><i> Loading securities...</i></p>
          @if (loadingSecurities) {
            <mat-spinner diameter="20"></mat-spinner>
          } @else {
            <i class="bi bi-check-lg text-success"></i>
          }
        </div>
        <div class="d-flex justify-content-center align-items-center gap-3">
          <p class="m-0"><i>Loading portfolios...</i></p>
          @if (loadingPortfolios) {
            <mat-spinner diameter="20"></mat-spinner>
          } @else {
            <i class="bi bi-check-lg text-success"></i>
          }
        </div>
      </div>
    }
  `
})
export class LandingComponent implements OnInit {
  public loadingSecurities = true;
  public loadingPortfolios = true;

  constructor(
    public authService: OrderFlowAuthService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private securitiesService: SecuritiesService,
    private portfoliosService: PortfoliosService,
    private logger: NGXLogger
  ) {}

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      combineLatest([
        this.securitiesService.securitiesSet$.pipe(tap((securitiesSet) => (this.loadingSecurities = !securitiesSet))),
        this.portfoliosService.portfoliosSet$.pipe(tap((portfoliosSet) => (this.loadingPortfolios = !portfoliosSet)))
      ])
        .pipe(map(([securitiesSet, portfoliosSet]) => securitiesSet && portfoliosSet))
        .subscribe((ready) => {
          if (ready) {
            this.logger.log('System is now ready');
            setTimeout(() => {
              this.router.navigate(['/home']);
            }, 500);
          }
        });
    }

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.setActiveAccount(payload.account);
        this.router.navigate(['/home']);
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        if (this.authService.loggedIn) {
          this.router.navigate(['/home']);
        }
      });
  }

  public login(): void {
    this.authService.login$().subscribe();
  }
}
